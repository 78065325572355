@import "bootstrap";
@import "config";
@import "proxima";
@import "../../../bower_components/font-awesome/scss/font-awesome";

@import "misc/z-index";
@import "partials/header";
@import "partials/application-form";
@import "partials/section-selection";
@import "partials/tags-input";

@import "pages/home";
@import "pages/help";
@import "pages/about";
@import "pages/contact";
@import "pages/solutions";

body {
    font-family: 'Source Sans Pro', Helvetica Neue, Helvetica, Arial, sans-serif;
}

#wrapper {
    overflow: hidden;
}
