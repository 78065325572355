& {
    background: #fff;

    .content-column {
        position: relative;
        @include make-md-column(6);

        @media (min-width: $grid-float-breakpoint) {
            height: 460px;

            .section-content {
                position: absolute;
                top: 50%;
                right: 0;
                left: 0;
                transform: translate(0, -50%);
                padding: 0;
            }
        }

        .section-content {
            padding: 20px;
        }

        .number {
            font-weight: 300;
            font-size: 79px;
            color: #F4F3FA;
            line-height: 94px;
        }

        h2 {
            font-weight: 300;
            font-size: 45px;
            color: #444444;
            line-height: 54px;
            margin: 0;
            padding: 0;
        }

        p {
            font-size: 16px;
            color: #777777;
            line-height: 23px;
        }

        a {
            font-weight: 600;
            font-size: 16px;
            color: $global-primary-color;
            line-height: 23px;
        }
    }

    .bg-column {
        @include make-md-column(6);
        height: 460px;
        background: url(../img/section2-image.png) no-repeat center bottom -140px;
        background-size: 100% auto;
        opacity: 0;
        transform: translate3d(0, 30px, 0);
        transition: all .6s ease-out .3s;
        display: none;

        @media (min-width: $screen-lg-min) {
            background-position: center bottom -240px;
        }

        @media (min-width: $grid-float-breakpoint) {
            display: block;
        }
    }

    &.is-visible {
        .bg-column {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}