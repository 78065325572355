@import "config";

@font-face {
    font-family: 'Proxima Nova';
    src: url("#{$font-path}/proximanova-bold-webfont.eot");
    src: url("#{$font-path}/proximanova-bold-webfont.eot?#iefix") format('embedded-opentype'),
         url("#{$font-path}/proximanova-bold-webfont.woff2") format('woff2'),
         url("#{$font-path}/proximanova-bold-webfont.woff") format('woff'),
         url("#{$font-path}/proximanova-bold-webfont.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("#{$font-path}/proximanova-reg-webfont.eot");
    src: url("#{$font-path}/proximanova-reg-webfont.eot?#iefix") format('embedded-opentype'),
         url("#{$font-path}/proximanova-reg-webfont.woff2") format('woff2'),
         url("#{$font-path}/proximanova-reg-webfont.woff") format('woff'),
         url("#{$font-path}/proximanova-reg-webfont.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}
