body.application-form-open {
    overflow: hidden;
}

#application-form {
    position: fixed;
    top: 0;
    left: 100%;
    bottom: 0;
    z-index: $application-form-z;
    width: 60%;
    background: #fff;
    transition: all .6s ease-in-out;
    box-shadow: -1px 0 15px -8px rgba(0, 0, 0, .4);

    &.open {
        transform: translate(-100%, 0);

        + .application-form-overlay {
            transform: translate(0, 0);
            opacity: 1;
            transition: opacity .3s ease-in-out .15s;
        }

        .close {
            transform: translate(0, 0);
            transition-delay: .15s;

            .line {
                &:nth-child(1) {
                    transform: rotate(-45deg) translate(-5px, 5px);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(45deg) translate(-6px, -6px);
                }
            }
        }
    }

    + .application-form-overlay {
        position: fixed;
        z-index: $application-form-z - 5;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        background: rgba(0, 0, 0, 0.6);
        transform: translate(-100%, 0);
        transition: opacity .3s ease-in-out .15s, transform 0s linear .6s;
    }

    .application-content {
        height: 100%;
        overflow: auto;
        -webkit-transform: translateZ(0);
    }

    .close {
        position: absolute;
        display: block;
        float: none;
        top: 54px;
        left: -54px;
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;
        background: $application-default-color;
        transform: translate(54px, 0);
        opacity: 1;
        @include border-left-radius(4px);
        transition: all .3s ease-in-out .45s;
        padding: 12px 14px 12px 16px;

        .line {
            width: 100%;
            margin: 6px 0;
            display: block;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            transition: all .3s ease-in-out;
        }
    }

    .header {
        background: $application-default-color;
        padding: 60px 80px 20px;

        .title {
            margin: 0;
            padding: 0;
            font-family: 'Source Sans Pro', Tahoma, sans-serif;
            font-size: 45px;
            color: #fff;
            line-height: 54px;
            font-weight: 200;
        }

        .desc {
            font-family: 'Source Sans Pro', Tahoma, sans-serif;
            font-size: 16px;
            color: #fff;
            line-height: 23px;
            font-weight: 300;
        }
    }

    .app-form {
        padding: 40px 80px;

        .title {
            font-family: 'Source Sans Pro', Tahoma, sans-serif;
            font-weight: 200;
            font-size: 45px;
            color: #444;
            line-height: 54px;
            margin-bottom: 20px;
        }

        label {
            font-family: Arial, sans-serif;
            font-weight: bold;
            font-size: 12px;
            color: #515356;
            line-height: 14px;
        }

        abbr {
            border: none;
        }

        .form-group.has-error {
            .control-label {
                color: $state-danger-text;
            }

            .form-control {
                border-color: $state-danger-text;
            }
        }

        .form-control {
            border: 1px solid #C6CFD9;
            border-radius: 3px;
            box-shadow: none;
            font-size: 16px;
            height: 50px;
            padding: 12px 12px;
        }

        .actions {
            display: flex;

            .btn-container {
                flex: 0 0 210px;
                align-self: center;

                .btn {
                    background: $global-primary-color;
                    box-shadow: 0 2px 0 #18B204;
                    border-radius: 40px;
                    padding: 9px 20px;
                    font-size: 20px;
                    color: #fff;
                    line-height: 30px;
                    font-weight: 600;
                    border: none;
                    width: 210px;

                    &:active,
                    &:focus {
                        outline: none;
                        border: none;
                    }

                    &:active {
                        margin-top: 2px;
                        margin-bottom: -2px;
                        box-shadow: none;
                    }
                }
            }

            .result {
                margin-left: 15px;
                align-self: center;

                &.result-success {
                    color: #2BAA44;
                }

                &.result-failed {
                    color: #841A11;
                }
            }
        }
    }
}