.page__solutions {
    .heading {
        background: #757363 url(../img/solutions-page-bg.jpg) no-repeat right bottom;
        background-size: auto 110%;
        display: flex;

        @media (min-width: $screen-md-min) {
            height: 305px;
        }

        @media (min-width: $screen-lg-min) {
            height: 370px;
        }

        .container {
            display: flex;

            h1 {
                align-self: flex-end;
                color: #fff;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 300;
                font-size: 50px;
                line-height: 63px;
                margin-top: 100px;
            }
        }
    }

    .secondary-header {
        background: #30609D;

        .container {
            padding-top: 20px;
            padding-bottom: 20px;

            @media (min-width: $screen-md-min) {
                display: flex;
            }

            .left {
                text-align: center;

                @media (min-width: $screen-md-min) {
                    text-align: left;
                    align-self: center;
                    flex: 1;
                }

                div {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 24px;
                    color: #fff;
                    line-height: 37px;

                    &:first-child {
                        font-weight: 300;
                    }

                    &:last-child {
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }

            .right {
                text-align: center;
                margin: 40px 0 20px;

                @media (min-width: $screen-md-min) {
                    margin: 0;
                    align-self: center;
                    text-align: right;
                }
            }
        }

        .btn-call-to-action {
            background: rgba(255, 255, 255, 0);
            box-shadow: none;
            border-radius: 40px;
            padding: 19px;
            font-size: 18px;
            color: #fff;
            font-weight: 600;
            border: 2px solid #fff;
            letter-spacing: 1.79px;
            text-transform: uppercase;
            line-height: 23px;
            width: 250px;
            transition: all .15s ease-in-out;

            &:hover {
                background: rgba(255, 255, 255, .1);
            }
        }
    }

    main {
        .section-1 {
            padding: 30px 0;

            .h4 {
                font-weight: 600;
                font-size: 17px;
                color: #838582;
                letter-spacing: 3px;
                line-height: 25px;
                text-transform: uppercase;
            }

            .h1 {
                font-weight: 300;
                font-size: 40px;
                color: #4A90E2;
                line-height: 50px;
            }

            .h2 {
                font-weight: 300;
                font-size: 40px;
                color: #42CB5D;
                line-height: 50px;
                margin-top: 90px;
            }

            .h3 {
                font-weight: 600;
                font-size: 24px;
                color: #838582;
                letter-spacing: 2.66px;
                line-height: 42px;
                text-transform: uppercase;
            }

            p {
                font-weight: 300;
                font-size: 17px;
                color: #9B9B9B;
                line-height: 29px;
            }

            .btn {
                background: $global-primary-color;
                box-shadow: 0 2px 0 #18B204;
                border-radius: 40px;
                padding: 19px 40px;
                font-size: 18px;
                color: #fff;
                font-weight: 600;
                border: 0;
                letter-spacing: 1.79px;
                line-height: 23px;

                &:active,
                &:focus {
                    outline: none;
                    border: none;
                }

                &:active {
                    margin-top: 2px;
                    margin-bottom: -2px;
                    box-shadow: none;
                }
            }
        }

        .pricing-section {
            text-align: center;
            background-color: #F4F5F7;
            padding: 30px 0;

            h1 {
                font-weight: 300;
                font-size: 40px;
                color: #42CB5D;
                line-height: 50px;
            }

            .desc {
                font-weight: 300;
                font-size: 17px;
                color: #9B9B9B;
                line-height: 29px;
            }

            .pricing {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;

                @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                    flex-wrap: wrap;
                }

                .price-box {
                    flex: 1;
                    border: 1px solid;
                    border-radius: 4px;
                    background: #ffffff;

                    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                        flex-basis: 49%;
                    }

                    @media (min-width: $screen-md-min) {
                        + .price-box {
                            margin-left: 15px;
                        }
                    }

                    &:first-child {
                        border-color: #79D28F;

                        header {
                            background-color: #79D28F;
                            color: #fff;
                        }
                    }

                    &:nth-child(2) {
                        border-color: #76C48A;

                        header {
                            background-color: #76C48A;
                            color: #fff;
                        }
                    }

                    &:nth-child(3) {
                        border-color: #75B485;

                        header {
                            background-color: #75B485;
                            color: #fff;
                        }
                    }

                    &:nth-child(4) {
                        border-color: #629E71;

                        header {
                            background-color: #629E71;
                            color: #fff;
                        }
                    }

                    header {
                        text-align: center;
                        padding: 20px 0;

                        div:first-child {
                            font-weight: 600;
                            font-size: 20px;
                        }

                        div:last-child {
                            font-weight: 600;
                            font-size: 16px;
                        }
                    }

                    .percentage {
                        font-weight: 600;
                        font-size: 68px;
                        color: #424753;
                        line-height: 70px;
                        margin-top: 10px;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #ededef;
                        width: 80%;
                        margin-left: 10%;
                        margin-bottom: 20px;
                        display: flex;
                        justify-content: center;

                        &:before {
                            content: "%";
                            font-weight: 400;
                            font-size: 28px;
                            color: #424753;
                            line-height: 36px;
                            margin-top: 7px;
                        }
                    }

                    .features {
                        list-style: none;
                        padding: 0 20px;
                        margin: 0;
                        font-weight: 400;
                        font-size: 15px;
                        color: #797D87;
                        line-height: 25px;
                    }

                    .btn {
                        background: transparent;
                        box-shadow: none;
                        border-radius: 40px;
                        padding: 16px;
                        font-size: 14px;
                        color: $global-primary-color;
                        font-weight: 600;
                        border: 2px solid $global-primary-color;
                        letter-spacing: 1.79px;
                        text-transform: uppercase;
                        line-height: 23px;
                        width: 80%;
                        margin: 20px auto;
                    }
                }
            }

            .special-pricing {
                border: 1px solid #83C794;
                border-radius: 4px;
                background: #ffffff;
                padding: 50px 50px 30px;
                margin-top: 40px;

                p {
                    font-weight: 300;
                    font-size: 20px;
                    color: #424753;
                    line-height: 26px;
                }

                .btn {
                    background: transparent;
                    box-shadow: none;
                    border-radius: 40px;
                    padding: 16px 40px;
                    font-size: 14px;
                    color: $global-primary-color;
                    font-weight: 600;
                    border: 2px solid $global-primary-color;
                    letter-spacing: 1.79px;
                    text-transform: uppercase;
                    line-height: 23px;
                    margin: 25px 0 0;
                }
            }
        }

        .section-3 {
            background: #d0ced3 url(../img/solutions-section-image-1.png) no-repeat right bottom;
            background-size: 90% auto;
            height: 600px;

            h1 {
                font-weight: 300;
                font-size: 40px;
                color: #4A90E2;
                line-height: 50px;
                margin-top: 90px;
            }

            .subheading {
                font-weight: 300;
                font-size: 17px;
                color: #5B5B5B;
                line-height: 29px;
            }

            .btn {
                background: $global-primary-color;
                box-shadow: 0 2px 0 #18B204;
                border-radius: 40px;
                padding: 17px 40px;
                font-size: 20px;
                color: #fff;
                line-height: 30px;
                margin-top: 35px;
                font-weight: 600;
                border: none;

                &:active,
                &:focus {
                    outline: none;
                    border: none;
                }

                &:active {
                    margin-top: 37px;
                    margin-bottom: -2px;
                    box-shadow: none;
                }
            }
        }

        .section-4 {
            background: #F5F5F6;

            h1 {
                font-weight: 300;
                font-size: 40px;
                color: #4A90E2;
                line-height: 50px;
                margin-top: 90px;
            }

            .subheading {
                font-weight: 300;
                font-size: 17px;
                color: #5B5B5B;
                line-height: 29px;
            }

            img {
                width: 100%;
            }

            h2 {
                font-weight: 300;
                font-size: 24px;
                color: #373737;
                line-height: 29px;

                ~ h2 {
                    margin-top: 40px;
                }
            }

            p {
                font-weight: 300;
                font-size: 17px;
                color: #373737;
                line-height: 29px;
            }
        }
    }
}