.page__help {
    .heading {
        background: url(../img/help-page-bg.jpg) no-repeat center center;
        background-size: cover;
        display: flex;

        @media (min-width: $screen-md-min) {
            height: 305px;
        }

        @media (min-width: $screen-lg-min) {
            height: 370px;
        }

        .container {
            display: flex;

            h1 {
                align-self: flex-end;
                color: #fff;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 300;
                font-size: 50px;
                line-height: 63px;
                margin-top: 100px;
            }
        }
    }

    .secondary-header {
        background: $global-primary-color;

        .container {
            padding-top: 20px;
            padding-bottom: 20px;

            @media (min-width: $screen-md-min) {
                display: flex;
            }

            .left {
                text-align: center;

                @media (min-width: $screen-md-min) {
                    text-align: left;
                    align-self: center;
                    flex: 1;
                }

                div {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 24px;
                    color: #fff;
                    line-height: 37px;

                    &:first-child {
                        font-weight: 300;
                    }

                    &:last-child {
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }

            .right {
                text-align: center;
                margin: 40px 0 20px;

                @media (min-width: $screen-md-min) {
                    margin: 0;
                    align-self: center;
                    text-align: right;
                }
            }
        }

        .btn-call-to-action {
            background: $global-primary-color;
            box-shadow: none;
            border-radius: 40px;
            padding: 19px;
            font-size: 18px;
            color: #fff;
            font-weight: 600;
            border: 2px solid #fff;
            letter-spacing: 1.79px;
            text-transform: uppercase;
            line-height: 23px;
            width: 250px;
        }
    }

    #questions {
        list-style: none;
        padding: 0;
        margin: 30px 0;

        > li {
            border-bottom: 1px solid #F2F2F2;

            &.open {
                header {
                    a {
                        color: #4A90E2
                    }

                    .indicator:before {
                        transform: rotate(-180deg);
                    }
                }

                > .content {
                    // display: block;
                }
            }

            header {
                display: flex;
                align-items: center;

                a {
                    display: block;
                    text-decoration: none;
                    cursor: pointer;
                    font-family: 'Source Sans Pro', sans-serif;
                    font-weight: 600;
                    font-size: 20px;
                    color: #9B9B9B;
                    line-height: 33px;
                    padding: 20px;
                    position: relative;
                    flex: 1;
                }

                .indicator {
                    cursor: pointer;

                    &:before {
                        font-family: FontAwesome;
                        content: "\f107";
                        color: #4A90E2;
                        font-size: 40px;
                        transform: rotate(0deg);
                        transition: all .3s ease-in-out;
                        display: block;
                    }
                }
            }

            > .content {
                display: none;
                padding: 0 20px 20px;
                font-weight: 300;
                font-size: 17px;
                color: #9B9B9B;
                line-height: 29px;
            }
        }
    }
}