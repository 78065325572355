& {
    z-index: 2;
    overflow-x: hidden;

    @media (min-width: $grid-float-breakpoint) {
        overflow: hidden;
    }

    img {
        position: absolute;
        z-index: -1;
        top: 0;
        width: 100%;
        transform: translate3d(0, 0, 0);

        @media (max-width: $grid-float-breakpoint-max) {
            filter: blur(4px);
            width: auto;
            height: 100%;
        }
    }

    .section-content {
        padding: 20px;

        @media (min-width: $grid-float-breakpoint) {
            position: absolute;
            top: 34%;
            right: 12%;
            left: 59%;
            padding: 0;
        }
    }

    h1 {
        font-size: 45px;
        font-weight: 300;
        color: #24201C;
        margin: 0;
        padding: 0;
        line-height: 1.3333;
    }

    .btn-call-to-action {
        background: $global-primary-color;
        box-shadow: 0 2px 0 #18B204;
        border-radius: 40px;
        padding: 17px;
        font-size: 20px;
        color: #fff;
        line-height: 30px;
        margin-top: 35px;
        font-weight: 600;
        border: none;
        width: 100%;

        @media (min-width: $grid-float-breakpoint) {
            width: 75%;
        }

        &:after {
            font-family: FontAwesome;
            content: "\f178";
        }

        &:active,
        &:focus {
            outline: none;
            border: none;
        }

        &:active {
            margin-top: 37px;
            margin-bottom: -2px;
            box-shadow: none;
        }
    }

    p {
        margin-top: 30px;
        font-size: 20px;
        color: #24201C;
        line-height: 30px;
        font-weight: 300;

        .action {
            color: inherit;
            text-decoration: none;
            font-weight: 400;
            transition: all .3s ease-out;

            &:hover {
                color: $global-primary-color;
                text-shadow: 0 1px 1px #18B204;
            }
        }
    }

    .continue-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 133px;
        height: 66px;
        display: none;

        @media (min-width: $grid-float-breakpoint) {
            display: block;
        }

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 132px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
        }

        &.wobble:before {
            animation-name: wobble;
            animation-duration: 3s;
            animation-iteration-count: infinite;
        }

        &:after {
            position: absolute;
            top: 4px;
            left: 50%;
            font-family: FontAwesome;
            content: "\f107";
            font-size: 70px;
            margin-left: -22.5px;
            color: #fff;
            transform: scale(1.1, 0.9);
            line-height: 1;
        }
    }
}

@keyframes wobble {
    0%, 42%, 50%, 58%, 100% {
        background: rgba(0, 0, 0, 0.4);
        transform: scale(1, 1);
    }

    46%, 54% {
        background: rgba(23, 47, 74, 0.4);
        transform: scale(1.15, 1.12);
    }
}
