.page__home {
    background: #e5e5e5;

    main {
        position: absolute;
        z-index: $main-z;
        top: 75px;
        right: 0;
        bottom: 0;
        left: 0;

        > section {
            position: relative;
        }

        > .primary {
            @import "sections/primary";
        }

        > .second {
            @import "sections/second";
        }

        > .third {
            @import "sections/third";
        }

        > .fourth {
            @import "sections/fourth";
        }
    }
}
