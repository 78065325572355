.page__about {
    .heading {
        background: url(../img/about-page-bg.jpg) no-repeat center center;
        background-size: cover;
        display: flex;

        @media (min-width: $screen-md-min) {
            height: 305px;
        }

        @media (min-width: $screen-lg-min) {
            height: 370px;
        }

        .container {
            display: flex;

            h1 {
                align-self: flex-end;
                color: #fff;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 300;
                font-size: 50px;
                line-height: 63px;
                margin-top: 100px;
            }
        }
    }

    .secondary-header {
        background: $global-primary-color;

        .container {
            padding-top: 20px;
            padding-bottom: 20px;

            @media (min-width: $screen-md-min) {
                display: flex;
            }

            .left {
                text-align: center;

                @media (min-width: $screen-md-min) {
                    text-align: left;
                    align-self: center;
                    flex: 1;
                }

                div {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 24px;
                    color: #fff;
                    line-height: 37px;

                    &:first-child {
                        font-weight: 300;
                    }

                    &:last-child {
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }

            .right {
                text-align: center;
                margin: 40px 0 20px;

                @media (min-width: $screen-md-min) {
                    margin: 0;
                    align-self: center;
                    text-align: right;
                }
            }
        }

        .btn-call-to-action {
            background: $global-primary-color;
            box-shadow: none;
            border-radius: 40px;
            padding: 19px;
            font-size: 18px;
            color: #fff;
            font-weight: 600;
            border: 2px solid #fff;
            letter-spacing: 1.79px;
            text-transform: uppercase;
            line-height: 23px;
            width: 250px;
        }
    }

    main {
        text-align: center;
        padding: 10px 0 30px;

        h2 {
            font-weight: 300;
            font-size: 40px;
            color: #42CB5D;
            line-height: 50px;
            margin: 40px 0;
        }

        p {
            font-weight: 300;
            font-size: 20px;
            color: #9B9B9B;
            line-height: 33px;
        }

        img {
            display: inline-block;
            margin: 30px 0;
        }
    }
}