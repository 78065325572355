.bootstrap-tagsinput {
    border: 1px solid #C6CFD9;
    border-radius: 3px;
    box-shadow: none;
    font-size: 16px;
    min-height: 50px;
    padding: 12px 12px;
    vertical-align: middle;
    cursor: text;
}
.bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
    color: #777;
    opacity: 1;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
    color: #777;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
    color: #777;
}
.bootstrap-tagsinput input:focus {
    border: none;
    box-shadow: none;
}
.bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: white;
}

.bootstrap-tagsinput .tag.label-info {
    background-color: #2BAA44;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
    content: "x";
    padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
