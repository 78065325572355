.page__contact {
    .heading {
        background: #F1F0F6 url(../img/contact-page-bg.jpg) no-repeat center center;
        background-size: cover;
        display: flex;

        @media (min-width: $screen-md-min) {
            background-position: right 15% bottom;
            background-size: auto 100%;
            height: 305px;
        }

        @media (min-width: $screen-lg-min) {
            height: 370px;
        }

        .container {
            display: flex;

            h1 {
                align-self: flex-end;
                color: #636469;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 300;
                font-size: 50px;
                line-height: 63px;
                margin-top: 100px;
            }
        }
    }

    .secondary-header {
        background: #5E7390;

        .container {
            padding-top: 20px;
            padding-bottom: 20px;

            @media (min-width: $screen-md-min) {
                display: flex;
            }

            .left {
                text-align: center;

                @media (min-width: $screen-md-min) {
                    text-align: left;
                    align-self: center;
                    flex: 1;
                }

                div {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 24px;
                    color: #fff;
                    line-height: 37px;

                    &:first-child {
                        font-weight: 300;
                    }

                    &:last-child {
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }

            .contact-infos {
                display: flex;
                flex-direction: column;

                @media (min-width: $screen-sm-min) {
                    flex-direction: row;
                }

                .contact-info {
                    display: flex;
                    align-items: center;
                    color: #fff;

                    &.contact-mail {
                        margin-bottom: 15px;

                        @media (min-width: $screen-sm-min) {
                            margin-bottom: 0;
                            margin-right: 60px;
                        }

                        .icon:before {
                            content: "\f0e0";
                        }
                    }

                    &.contact-phone {
                        .icon:before {
                            line-height: 52px;
                            content: "\f095";
                        }
                    }
                }

                .icon:before {
                    display: block;
                    font-family: FontAwesome;
                    font-size: 20px;
                    color: #FFFFFF;
                    border: 2px solid rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    width: 52px;
                    height: 52px;
                    line-height: 48px;
                    text-align: center;
                    margin-right: 15px;
                }

                .contact-content {
                    font-weight: 600;
                    font-size: 20px;
                    color: #FFFFFF;
                    line-height: 25px;
                }
            }
        }

        .btn-call-to-action {
            background: $global-primary-color;
            box-shadow: none;
            border-radius: 40px;
            padding: 19px;
            font-size: 18px;
            color: #fff;
            font-weight: 600;
            border: 2px solid #fff;
            letter-spacing: 1.79px;
            text-transform: uppercase;
            line-height: 23px;
            width: 250px;
        }
    }

    main {
        padding: 10px 0 30px;

        .h1 {
            font-weight: 300;
            margin: 40px 0;
            font-size: 45px;
            color: #444444;
            line-height: 56px;
        }

        .h2 {
            font-weight: 600;
            font-size: 24px;
            color: #5E7390;
            line-height: 33px;

            &.address-title {
                @media (min-width: $screen-md-min) {
                    margin-top: 80px;
                }
            }
        }

        p {
            font-weight: 300;
            font-size: 20px;
            line-height: 33px;
            color: #9B9B9B;
        }

        .apply-now {
            font-weight: 600;
            font-size: 20px;
            color: $global-primary-color;
            text-decoration: none;
        }

        label {
            font-family: Arial, sans-serif;
            font-weight: bold;
            font-size: 12px;
            color: #515356;
            line-height: 14px;
        }

        abbr {
            border: none;
        }

        .form-group + .form-group {
            margin-top: 40px;
        }

        .form-group.has-error {
            .control-label {
                color: $state-danger-text;
            }

            .form-control {
                border-color: $state-danger-text;
            }
        }

        .form-control {
            border: 1px solid #C6CFD9;
            border-radius: 3px;
            box-shadow: none;
            font-size: 16px;
            height: 50px;
            padding: 12px 12px;
            resize: vertical;
        }

        .btn {
            background: $global-primary-color;
            box-shadow: 0 2px 0 #18B204;
            border-radius: 40px;
            padding: 9px 20px;
            font-size: 20px;
            color: #fff;
            line-height: 30px;
            font-weight: 600;
            border: none;
            width: 210px;

            &:active,
            &:focus {
                outline: none;
                border: none;
            }

            &:active {
                margin-top: 2px;
                margin-bottom: -2px;
                box-shadow: none;
            }
        }
    }
}