#section-selection {
    position: fixed;
    z-index: $section-selection-z;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    list-style: none;
    width: 40px;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 20px;
    display: none;
    @include clearfix;

    @media (min-width: $grid-float-breakpoint) {
        display: block;
    }

    > li {
        display: block;
        float: left;

        > a {
            position: relative;
            display: block;
            width: 20px;
            height: 10px;
            margin: 10px 0;
            border-radius: 50%;

            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 5px;
                width: 10px;
                height: 10px;
                border-radius: inherit;
                background: #fff;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, .20);
            }
        }

        &.active {
            > a {
                width: 22px;
                height: 22px;
                border: 2px solid #fff;
                margin: 4px -1px;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, .20);

                &:before {
                    top: 4px;
                    left: 4px;
                }
            }
        }
    }
}