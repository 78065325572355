& {
    background: #E0EEEF;
    overflow: hidden;

    &:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../img/section4-image.png) no-repeat right bottom;
        background-size: 50% auto;
        opacity: 0;
        transition: all .6s ease-out .3s;
        transform: translate3d(35px, 0, 0);
        display: none;

        @media (min-width: $grid-float-breakpoint) {
            display: block;
        }
    }

    &.is-visible:before {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    .content-column {
        position: relative;
        @include make-md-column(6);

        @media (min-width: $grid-float-breakpoint) {
            height: 520px;

            .section-content {
                position: absolute;
                top: 50%;
                right: 0;
                left: 0;
                transform: translate(0, -50%);
                padding: 0;
            }
        }

        .section-content {
            padding: 20px;
        }

        .number {
            font-weight: 300;
            font-size: 79px;
            color: #C8DBDD;
            line-height: 94px;
        }

        h2 {
            font-weight: 300;
            font-size: 45px;
            color: #444444;
            line-height: 54px;
            margin: 0;
            padding: 0;
        }

        p {
            font-size: 16px;
            color: #777777;
            line-height: 23px;
        }

        a {
            font-weight: 600;
            font-size: 16px;
            color: $global-primary-color;
            line-height: 23px;
        }
    }
}