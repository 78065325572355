#header {
    position: fixed;
    z-index: $header-z;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10);
    @include clearfix;

    .stuff {
        @media (max-width: $grid-float-breakpoint-max) {
            @include clearfix;
        }

        @media (min-width: $grid-float-breakpoint) {
            float: left;
        }
    }

    .logo {
        display: block;
        width: 116px;
        height: 42px;
        background: url(../img/logo.png) no-repeat center center;
        background-size: contain;
        float: left;
        margin: 20px 16px 13px;

        @media (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(../img/logo@2x.png);
        }
    }

    .navbar-toggle {
        margin: 22px;
        border-color: #e5e5e5;
        display: block;

        @media (min-width: $grid-float-breakpoint) {
            display: none;
        }

        .icon-bar {
            background-color: #e5e5e5;
        }
    }

    #navbar {
        @media (min-width: $grid-float-breakpoint) {
            float: right;
            padding-right: 0;
        }

        > ul {
            margin-bottom: 0;

            > li {
                @media (min-width: $grid-float-breakpoint) {
                    border-left: 1px solid #ececec;
                }

                &:first-child {
                    border-left: none;
                }

                > a {
                    color: #636469;
                    background: none;
                    text-decoration: none;
                    @include transition(color .3s ease-out);

                    @media (min-width: $grid-float-breakpoint) {
                        padding: 29px 22px 28px;
                        font-size: 15px;
                        line-height: 18px;
                    }

                    &:hover, &:focus, &:active {
                        color: #4A90E2;
                    }
                }

                &.language {
                    margin-right: 15px;

                    @media (min-width: $grid-float-breakpoint) {
                        background: url(../img/language-bg.png) no-repeat center center;
                        background-size: 28px 28px;
                        @include img-retina("../img/language-bg.png", "../img/language-bg@2x.png", 28px, 28px);
                    }

                    > a:after {
                        position: absolute;
                        font-family: FontAwesome;
                        font-weight: normal;
                        content: "\f078";
                        font-size: 10px;
                        color: #757C84;
                        margin: 4px 0 0 4px;
                        opacity: 0.55;
                        line-height: 10px;
                    }

                    > ul.dropdown-menu {
                        border: none;
                        border-radius: 0;

                        @media (min-width: $grid-float-breakpoint) {
                            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) /* right */, -1px 1px 1px rgba(0, 0, 0, 0.1) /* left */;
                            right: -16px;
                        }

                        a {
                            color: #636469;
                            background: none;
                            text-decoration: none;
                            @include transition(color .3s ease-out);

                            &:hover, &:focus, &:active {
                                color: #4A90E2;
                            }
                        }
                    }
                }

                &.login-button {
                    > a {
                        background: #4A90E2;
                        color: #fff;
                        padding-left: 47px;

                        @media (max-width: $grid-float-breakpoint-max) {
                            line-height: 30px;
                        }

                        @media (min-width: $grid-float-breakpoint) {
                            padding-left: 65px;
                        }

                        &:before {
                            content: "";
                            width: 28px;
                            height: 100%;
                            background: url(../img/menu-login.png) no-repeat center center;
                            background-size: 20px 18px;
                            position: absolute;
                            left: 7px;
                            top: 0;

                            @media (min-width: $grid-float-breakpoint) {
                                left: 25px;
                                background-size: 28px 25px;
                                @include img-retina("../img/menu-login.png", "../img/menu-login@2x.png", 28px, 25px);
                            }
                        }
                    }
                }
            }
        }
    }
}